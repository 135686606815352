import { BADGE_TYPES } from 'constants/common'
import keymirror from 'keymirror'
import _ from 'lodash'

// constants
import {
  ASYNC_SELECT_FILTER_TYPE,
  DATE_RANGE_FILTER_VALUES,
} from 'constants/filter'

import type { Option } from 'types/common'
import type { DateFilterOptions } from 'types/filter'

// utils
import { loadSitesForDropdown } from 'app/MissionControlMethaneSolution/services/api/site'
import { transformSitesToOptions } from 'app/MissionControlMethaneSolution/helpers/detection'
import { transformDateFilterValue } from 'helpers/filter'

import { IS_FEATURE_ENABLED, DETECTION_TYPES } from './common'

export const DETECTION_PRIORITY_TYPES = keymirror({
  DETECTION_PRIORITY_HIGH: null,
  DETECTION_PRIORITY_LOW: null,
  DETECTION_PRIORITY_MEDIUM: null,
})

export const DETECTION_PRIORITY_TYPES_OPTION = [
  DETECTION_PRIORITY_TYPES.DETECTION_PRIORITY_HIGH,
  DETECTION_PRIORITY_TYPES.DETECTION_PRIORITY_MEDIUM,
  DETECTION_PRIORITY_TYPES.DETECTION_PRIORITY_LOW,
]

export const COMPLIANCE_SEVERITY_TYPES = keymirror({
  HIGH: null,
  MEDIUM: null,
  LOW: null,
})

export const MANUAL_FORM_DETECTION_TYPE_LABEL = 'Manual'

export const UNKNOWN_DETECTION_TYPE_LABEL = 'Other'

const BASE_DETECTION_TYPE_OPTIONS = [
  {
    label: 'CMS',
    value: DETECTION_TYPES.DETECTION_TYPE_CMS,
  },
  {
    label: 'Drone',
    value: DETECTION_TYPES.DETECTION_TYPE_DRONE,
  },
  {
    label: 'Flyover',
    value: DETECTION_TYPES.DETECTION_TYPE_FLYOVER,
  },
  {
    label: 'OGI',
    value: DETECTION_TYPES.DETECTION_TYPE_OGI,
  },
  {
    label: 'On-site Walk Down',
    value: DETECTION_TYPES.DETECTION_TYPE_OWD,
  },
  {
    label: 'Satellite',
    value: DETECTION_TYPES.DETECTION_TYPE_SATELLITE,
  },
]

export const DETECTION_TYPE_OPTIONS = [
  ...BASE_DETECTION_TYPE_OPTIONS,
  {
    label: UNKNOWN_DETECTION_TYPE_LABEL,
    value: DETECTION_TYPES.DETECTION_TYPE_UNKNOWN,
  },
]

export const EMISSION_EVENT_TYPE_OPTIONS = [
  ...BASE_DETECTION_TYPE_OPTIONS,
  {
    label: 'VFB',
    value: DETECTION_TYPES.DETECTION_TYPE_VENTING_FLARING_BLOWDOWN,
  },
  {
    label: UNKNOWN_DETECTION_TYPE_LABEL,
    value: DETECTION_TYPES.DETECTION_TYPE_UNKNOWN,
  },
]

export const DETECTION_TYPE_KEY_BY_VALUE = _.keyBy(
  EMISSION_EVENT_TYPE_OPTIONS,
  'value'
)

export const DETECTION_PURPOSES = keymirror({
  DETECTION_PURPOSE_FOLLOWUP: null,
  DETECTION_PURPOSE_REPAIR: null,
  DETECTION_PURPOSE_SCHEDULED: null,
  DETECTION_PURPOSE_VERIFICATION: null,
})

export const DETECTION_PURPOSE_OPTIONS = [
  {
    label: 'Follow-up',
    value: DETECTION_PURPOSES.DETECTION_PURPOSE_FOLLOWUP,
  },
  {
    label: 'Repair',
    value: DETECTION_PURPOSES.DETECTION_PURPOSE_REPAIR,
  },
  {
    label: 'Scheduled',
    value: DETECTION_PURPOSES.DETECTION_PURPOSE_SCHEDULED,
  },
  {
    label: 'Verification',
    value: DETECTION_PURPOSES.DETECTION_PURPOSE_VERIFICATION,
  },
]

export const DETECTION_STATUS = keymirror({
  DETECTION_STATUS_NEW: null,
  DETECTION_STATUS_VISUAL_INSPECTION_REQUESTED: null,
  DETECTION_STATUS_VISUAL_INSPECTION_SCHEDULED: null,
  DETECTION_STATUS_VISUAL_INSPECTION_COMPLETED: null,
  DETECTION_STATUS_OGI_REQUESTED: null,
  DETECTION_STATUS_OGI_SCHEDULED: null,
  DETECTION_STATUS_OGI_COMPLETED: null,
  DETECTION_STATUS_LEAK_REPAIR_SCHEDULED: null,
  DETECTION_STATUS_LEAK_REPAIRED: null,
  DETECTION_STATUS_VERIFICATION_SCHEDULED: null,
  DETECTION_STATUS_RESOLVED: null,
  DETECTION_STATUS_VFB_REPORT_REQUESTED: null,
  DETECTION_STATUS_PENDING_RESOLUTION: null,
  NEW: null,
})

export const DETECTION_STATUS_LABELS_MAPPING = {
  [DETECTION_STATUS.DETECTION_STATUS_NEW]: 'New',
  [DETECTION_STATUS.DETECTION_STATUS_VISUAL_INSPECTION_REQUESTED]:
    'Visual inspection requested',
  [DETECTION_STATUS.DETECTION_STATUS_VISUAL_INSPECTION_SCHEDULED]:
    'Visual inspection scheduled',
  [DETECTION_STATUS.DETECTION_STATUS_VISUAL_INSPECTION_COMPLETED]:
    'Visual inspection completed',
  [DETECTION_STATUS.DETECTION_STATUS_OGI_REQUESTED]: 'OGI requested',
  [DETECTION_STATUS.DETECTION_STATUS_OGI_SCHEDULED]: 'OGI scheduled',
  [DETECTION_STATUS.DETECTION_STATUS_OGI_COMPLETED]: 'OGI completed',
  [DETECTION_STATUS.DETECTION_STATUS_LEAK_REPAIR_SCHEDULED]:
    'Leak repair scheduled',
  [DETECTION_STATUS.DETECTION_STATUS_LEAK_REPAIRED]: 'Leak repaired',
  [DETECTION_STATUS.DETECTION_STATUS_VERIFICATION_SCHEDULED]:
    'Verification scheduled',
  [DETECTION_STATUS.DETECTION_STATUS_RESOLVED]: 'Completed',
  [DETECTION_STATUS.DETECTION_STATUS_VFB_REPORT_REQUESTED]: 'Investigation',
  [DETECTION_STATUS.DETECTION_STATUS_PENDING_RESOLUTION]: 'In Progress',
}

export const WORK_ITEM_LABELS_MAPPING = {
  [DETECTION_STATUS.DETECTION_STATUS_NEW]: 'VFB report',

  [DETECTION_STATUS.DETECTION_STATUS_VISUAL_INSPECTION_REQUESTED]: 'VFB report',
  [DETECTION_STATUS.DETECTION_STATUS_VISUAL_INSPECTION_SCHEDULED]: 'VFB report',
  [DETECTION_STATUS.DETECTION_STATUS_VISUAL_INSPECTION_COMPLETED]: 'VFB report',

  [DETECTION_STATUS.DETECTION_STATUS_OGI_REQUESTED]: 'OGI',
  [DETECTION_STATUS.DETECTION_STATUS_OGI_SCHEDULED]: 'OGI',
  [DETECTION_STATUS.DETECTION_STATUS_OGI_COMPLETED]: 'OGI',

  [DETECTION_STATUS.DETECTION_STATUS_LEAK_REPAIR_SCHEDULED]: 'Leak',
  [DETECTION_STATUS.DETECTION_STATUS_LEAK_REPAIRED]: 'Leak',
  [DETECTION_STATUS.DETECTION_STATUS_VERIFICATION_SCHEDULED]: 'Leak',

  [DETECTION_STATUS.DETECTION_STATUS_PENDING_RESOLUTION]: 'VFB report',
  [DETECTION_STATUS.DETECTION_STATUS_RESOLVED]: 'VFB report',
  [DETECTION_STATUS.DETECTION_STATUS_VFB_REPORT_REQUESTED]: 'VFB report',
}

export const PROGRESS_MAPPING = {
  [DETECTION_STATUS.DETECTION_STATUS_NEW]: 0,

  [DETECTION_STATUS.DETECTION_STATUS_VISUAL_INSPECTION_REQUESTED]: 40,
  [DETECTION_STATUS.DETECTION_STATUS_VISUAL_INSPECTION_SCHEDULED]: 50,
  [DETECTION_STATUS.DETECTION_STATUS_VISUAL_INSPECTION_COMPLETED]: 60,

  [DETECTION_STATUS.DETECTION_STATUS_OGI_REQUESTED]: 20,
  [DETECTION_STATUS.DETECTION_STATUS_OGI_SCHEDULED]: 40,
  [DETECTION_STATUS.DETECTION_STATUS_OGI_COMPLETED]: 50,

  [DETECTION_STATUS.DETECTION_STATUS_LEAK_REPAIR_SCHEDULED]: 70,
  [DETECTION_STATUS.DETECTION_STATUS_LEAK_REPAIRED]: 80,
  [DETECTION_STATUS.DETECTION_STATUS_VERIFICATION_SCHEDULED]: 90,

  [DETECTION_STATUS.DETECTION_STATUS_PENDING_RESOLUTION]: 95,
  [DETECTION_STATUS.DETECTION_STATUS_RESOLVED]: 100,

  [DETECTION_STATUS.DETECTION_STATUS_VFB_REPORT_REQUESTED]: 30,
}

export const PRIORITY_OPTIONS = [
  {
    value: DETECTION_PRIORITY_TYPES.DETECTION_PRIORITY_HIGH,
    label: 'High',
    badgeType: BADGE_TYPES.error,
    colour: 'danger',
    mapColour: [227, 0, 1],
  },
  {
    value: DETECTION_PRIORITY_TYPES.DETECTION_PRIORITY_MEDIUM,
    label: 'Medium',
    badgeType: BADGE_TYPES.warning,
    colour: 'warning',
    textColour: 'yellow-700',
    mapColour: [227, 0, 1],
  },
  {
    value: DETECTION_PRIORITY_TYPES.DETECTION_PRIORITY_LOW,
    label: 'Low',
    badgeType: BADGE_TYPES.infoGrey,
    colour: 'secondary',
    mapColour: [227, 0, 1],
  },
]

export const COMPLIANCE_BADGE_TYPE_MAPPING = {
  [COMPLIANCE_SEVERITY_TYPES.HIGH]: BADGE_TYPES.error,
  [COMPLIANCE_SEVERITY_TYPES.MEDIUM]: BADGE_TYPES.warning,
  [COMPLIANCE_SEVERITY_TYPES.LOW]: BADGE_TYPES.success,
}

export const WORK_ITEM_BADGE_TYPE_MAPPING = {
  'VFB report': BADGE_TYPES.warning,
  OGI: BADGE_TYPES.infoBlue,
  Leak: BADGE_TYPES.infoGrey,
}

export const PRIORITY_OPTIONS_KEY_BY_VALUE = _.keyBy(PRIORITY_OPTIONS, 'value')

export const DETECTION_CLUE_TYPES = keymirror({
  CLUE_TYPE_CMS: null,
  CLUE_TYPE_OTHER: null,
  CLUE_TYPE_VENTING_FLARING_BLOWDOWN: null,
  CLUE_TYPE_DRILLING_AND_WELL: null,
  CLUE_TYPE_REAL_TIME_DATA: null,
  CLUE_TYPE_OGI_INSPECTION: null,
  CLUE_TYPE_MAINTENANCE_NOTIFICATION: null,
  CLUE_TYPE_OPEN_WORK_ORDER: null,
})

export const DETECTION_CLUE_LABEL_MAPPING = {
  [DETECTION_CLUE_TYPES.CLUE_TYPE_CMS]: 'Continuous Monitoring',
  [DETECTION_CLUE_TYPES.CLUE_TYPE_OTHER]: 'Other detection sources',
  [DETECTION_CLUE_TYPES.CLUE_TYPE_VENTING_FLARING_BLOWDOWN]:
    'Venting / flaring and blowdowns',
  [DETECTION_CLUE_TYPES.CLUE_TYPE_DRILLING_AND_WELL]:
    'Drilling and well services',
  [DETECTION_CLUE_TYPES.CLUE_TYPE_REAL_TIME_DATA]: 'Real-time data',
  [DETECTION_CLUE_TYPES.CLUE_TYPE_OGI_INSPECTION]: 'Scheduled OGI inspections',
  [DETECTION_CLUE_TYPES.CLUE_TYPE_MAINTENANCE_NOTIFICATION]:
    'Maintenance notifications',
  [DETECTION_CLUE_TYPES.CLUE_TYPE_OPEN_WORK_ORDER]: 'Open Work orders',
}

export const INSPECTION_TYPES = keymirror({
  INSPECTION_OGI: null,
  INSPECTION_VISUAL: null,
})

export const CLUE_TYPES = keymirror({
  CLUE_TYPE_CMS: null,
  CLUE_TYPE_OTHER: null,
  CLUE_TYPE_VENTING_FLARING_BLOWDOWN: null,
  CLUE_TYPE_DRILLING_AND_WELL: null,
  CLUE_TYPE_REAL_TIME_DATA: null,
  CLUE_TYPE_OGI_INSPECTION: null,
  CLUE_TYPE_MAINTENANCE_NOTIFICATION: null,
  CLUE_TYPE_OPEN_WORK_ORDER: null,
})

export const CLUES_TO_EXCLUDE = [
  CLUE_TYPES.CLUE_TYPE_REAL_TIME_DATA,
  CLUE_TYPES.CLUE_TYPE_CMS,
]

export const RESOLUTION_MODE = keymirror({
  DETECTION_RESOLVED_CORRELATED: null,
  DETECTION_RESOLVED_FALSE_ALARM: null,
})
export const RESOLUTION_MODE_MAPPING = {
  [RESOLUTION_MODE.DETECTION_RESOLVED_CORRELATED]: 'Correlated',
  [RESOLUTION_MODE.DETECTION_RESOLVED_FALSE_ALARM]: 'False alarm',
}

export const DETECTION_PRIORITY_TOOLTIPS = {
  [DETECTION_PRIORITY_TYPES.DETECTION_PRIORITY_HIGH]: {
    text: 'Emission rate is greater than <%=firstValue%> <%=label%> or the detection is not actioned for more than 4 days since detection date',
    firstValue: 50,
  },
  [DETECTION_PRIORITY_TYPES.DETECTION_PRIORITY_LOW]: {
    text: 'Emission rate is less than <%=firstValue%> <%=label%> and the rate is less than 2 days passed since detection date',
    firstValue: 20,
  },

  [DETECTION_PRIORITY_TYPES.DETECTION_PRIORITY_MEDIUM]: {
    text: 'Emission rate is between <%=firstValue%> - <%=lastValue%> <%=label%> or the rate is less than <%=firstValue%> <%=label%>   and is not actioned for 2 - 4 days since detection date',
    firstValue: 20,
    lastValue: 50,
  },
}

export const EMISSION_RATE_UNITS = keymirror({
  kg: null,
  scf: null,
})

export const EMISSION_RATE_UNIT_LABELS = {
  [EMISSION_RATE_UNITS.kg]: 'kg/h',
  [EMISSION_RATE_UNITS.scf]: 'scf/h',
}

export const EMISSION_RATE_UNIT_OPTIONS = [
  { value: EMISSION_RATE_UNITS.kg, label: EMISSION_RATE_UNIT_LABELS.kg },
  { value: EMISSION_RATE_UNITS.scf, label: EMISSION_RATE_UNIT_LABELS.scf },
]

/** 1 scf = 0.01919 kg */
export const SCF_TO_KG_CONVERSION_FACTOR = 0.01919

export const DETECTION_LIST_FILTER_TYPES = keymirror({
  siteId: null,
  outlier: null,
  acknowledged: null,
  priority: null,
  detectionType: null,
  detectedAfter: null,
  detectedBefore: null,
})

export const DETECTION_DATE_FILTER_SPECS = [
  {
    key: DETECTION_LIST_FILTER_TYPES.detectedAfter,
    getFilterValue: (value: string, options: { timezone: string }) => {
      // For detections, if 'This month' is selected – don't provide any value and let the backend do the default stuff
      if (value === DATE_RANGE_FILTER_VALUES.this_month) return null

      return transformDateFilterValue(value, options)
    },
    enable: false,
    // Prevent from cleaning it up when user is clicking "Clear" on the filters panel
    isClearable: false,
  },
  {
    key: DETECTION_LIST_FILTER_TYPES.detectedBefore,
    getFilterValue: transformDateFilterValue,
    enable: false,
    isClearable: false,
  },
]

export const DETECTION_FILTER_SPECS = [
  {
    key: DETECTION_LIST_FILTER_TYPES.siteId,
    label: 'Site name',
    icon: 'MethaneSitesIcon',
    isPureBackendFilter: true,
    isMulti: true,
    type: ASYNC_SELECT_FILTER_TYPE,
    loadOptions: (searchTerm: string): Promise<Option[]> =>
      loadSitesForDropdown({
        transformToOptions: transformSitesToOptions,
        searchTerm,
      }),
    getFilterValue: (values: Option[]): string[] => _.map(values, 'value'),
  },
  ...(IS_FEATURE_ENABLED.SITE_OUTLIERS
    ? [
        {
          key: DETECTION_LIST_FILTER_TYPES.outlier,
          label: 'Is outlier',
          icon: 'TbFlag3',
          isPureBackendFilter: true,
          isMulti: false,
          options: [
            { value: true, label: 'Yes' },
            { value: false, label: 'No' },
          ],
        },
      ]
    : []),
  {
    key: DETECTION_LIST_FILTER_TYPES.acknowledged,
    label: 'Is read',
    icon: 'MethaneInboxIcon',
    isPureBackendFilter: true,
    isMulti: false,
    options: [
      { value: true, label: 'Yes' },
      { value: false, label: 'No' },
    ],
  },
  {
    key: DETECTION_LIST_FILTER_TYPES.priority,
    label: 'Priority',
    icon: 'HiOutlineExclamationCircle',
    isPureBackendFilter: true,
    isMulti: true,
    options: [
      {
        value: DETECTION_PRIORITY_TYPES.DETECTION_PRIORITY_HIGH,
        label: 'High',
      },
      {
        value: DETECTION_PRIORITY_TYPES.DETECTION_PRIORITY_MEDIUM,
        label: 'Medium',
      },
      {
        value: DETECTION_PRIORITY_TYPES.DETECTION_PRIORITY_LOW,
        label: 'Low',
      },
    ],
  },
  {
    key: DETECTION_LIST_FILTER_TYPES.detectionType,
    label: 'Emission event type',
    isPureBackendFilter: true,
    isMulti: true,
    icon: 'MethaneManualIcon',
    options: EMISSION_EVENT_TYPE_OPTIONS,
  },
  ...DETECTION_DATE_FILTER_SPECS,
]

/** ENUM representation of fields that can be used to sort the detections query */
export const DETECTION_SORT_BY_FIELD_PARAMS = keymirror({
  DETECTED_AT: null,
  SITE_NAME: null,
  EMISSIONS_RATE: null,
  PRIORITY: null,
  SHORT_ID: null,
  LAST_UPDATED: null,
})

/** Field key to ENUM */
export const DETECTION_FIELD_TO_SORT_PARAM_MAP: Record<
  string,
  keyof typeof DETECTION_SORT_BY_FIELD_PARAMS
> = {
  detectedAt: DETECTION_SORT_BY_FIELD_PARAMS.DETECTED_AT,
  site: DETECTION_SORT_BY_FIELD_PARAMS.SITE_NAME,
  emissionsRate: DETECTION_SORT_BY_FIELD_PARAMS.EMISSIONS_RATE,
  priority: DETECTION_SORT_BY_FIELD_PARAMS.PRIORITY,
  shortId: DETECTION_SORT_BY_FIELD_PARAMS.SHORT_ID,
  lastUpdatedTime: DETECTION_SORT_BY_FIELD_PARAMS.LAST_UPDATED,
}

export const DETECTION_TABLE_VIEWS = keymirror({
  INBOX: null,
  WIP: null,
})

export const DATE_FILTER_TOOLBOX_SETTINGS = {
  enableDateFilter: true,
  dateFilterOptions: {
    tooltipText: 'Filter by detection date',
    // ~3 months
    maxDaysRange: 93,
    getDateRangeFromFilter: filterValues => {
      const filters = filterValues || {}

      return {
        startDate:
          (filters.detectedAfter as string) || DATE_RANGE_FILTER_VALUES.today,
        endDate: filters.detectedBefore as string,
      }
    },
    transformSelectedRangeForFilter: value => {
      const [detectedAfter, detectedBefore] = value
      return { detectedAfter, detectedBefore }
    },
  } as DateFilterOptions,
}

/** List of fields available for search */
export const DETECTION_SEARCH_FIELDS = keymirror({
  siteName: null,
  siteId: null,
  screeningId: null,
  equipmentId: null,
  // TODO: need the backend support
  // detectionType https://sensorup.atlassian.net/browse/OXY-585
})

export const INBOX_SEARCH_FIELD_OPTIONS = [
  { value: DETECTION_SEARCH_FIELDS.screeningId, label: 'Screening ID' },
  { value: DETECTION_SEARCH_FIELDS.equipmentId, label: 'Equipment ID' },
]

/** Field options for <SearchBar /> */
export const DETECTION_SEARCH_FIELD_OPTIONS = [
  { value: DETECTION_SEARCH_FIELDS.siteName, label: 'Site' },
  { value: DETECTION_SEARCH_FIELDS.siteId, label: 'Site ID' },
  ...INBOX_SEARCH_FIELD_OPTIONS,
]

export const DETECTION_GALLERY_SEARCH_TOOLBOX_SETTINGS = {
  searchBarPlaceholder: 'Search detections',
  enableSearchFields: true,
  enableSearchDebounce: true,
  defaultSearchField: DETECTION_SEARCH_FIELDS.siteName,
  searchFieldsOptions: DETECTION_SEARCH_FIELD_OPTIONS,
}

export const CLOSE_ACTION_FIELD_NAMES = keymirror({
  explanation: null,
  type: null,
  correlatedEvent: null,
  correlatedClue: null,
})

export const DETECTION_CURRENT_CATEGORIES = keymirror({
  WIP: null,
})

export const CORRELATE_EVENT_TYPES = {
  ...DETECTION_CURRENT_CATEGORIES,
  ...keymirror({
    CLUE: null,
  }),
}

export const ADDITIONAL_COMMENTS_CHAR_LIMIT = 225

export const ADDITIONAL_COMMENTS_LABEL = 'Additional comments'

export const DETECTION_EMISSION_UNIT_LABEL = 'kg/hr'

export const NO_SITE_PAGE_NAME = 'no-site'

export const INBOX_OSI_PI_LINK =
  'https://pivision-us-d.oxy.com/PIVision/#/Displays/4464/EMAP-MVP'

export const EMISSION_RATE_MAX_DECIMALS = 2

export const DETECTION_DEFAULT_FILTER_KEYS_LIST = [
  'detectedAfter',
  'detectedBefore',
  'closed',
  'siteId',
]

export const QUBE_DEFAULT_PAGE_LINK = 'https://dashboard.qubeiot.com/'

export const LAST_UPDATED_TIME_FIELD = 'lastUpdatedTime'
